<template>
  <section
    ref="refAccordionGalleryBlock"
    class="accordion-gallery-block"
  >
    <div class="container">
      <h2
        v-if="values.heading"
        class="accordion-gallery-block__title"
        v-html="values.heading"
      />

      <div
        v-if="descriptionFromValues"
        v-html="descriptionFromValues"
        class="accordion-gallery-block__description"
      />

      <template v-if="$viewport.isGreaterOrEquals('lg')">
        <div
          class="accordion-gallery-block__swiper"
          :style="`--active-index: ${activeSlide}`"
        >
          <div
            v-for="(slide, index) in values.slides"
            :key="`slide-${index}`"
            :class="{'--active': Boolean(index === activeSlide)}"
          >
            <Card
              :item="slide"
              :active="Boolean(index === activeSlide)"

              @click="() => setSlide(index)"
              @prevSlide="prevSlide"
              @nextSlide="nextSlide"
            />
          </div>
        </div>
        <div v-if="false" class="accordion-gallery-block__scroll-bar"/>
      </template>
      <template v-if="!$viewport.isGreaterOrEquals('lg')">
        <MobileTemplate
          :slides="values.slides"
        />
      </template>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import {computed} from "#imports";

const Card = defineAsyncComponent(() => import('~/components/accordion-gallery/Card.vue'));
const MobileTemplate = defineAsyncComponent(() => import('~/components/accordion-gallery/MobileTemplate.vue'));

const props = defineProps<IBlockDefaultProps>();
const {
  $viewport
} = useNuxtApp();
const $route = useRoute();

const activeSlide: any = ref(0);
const refAccordionGalleryBlock: any = ref(null);

const setSlide = (index: number) => {
  activeSlide.value = index;
};
const prevSlide = () => {
  const newVal = activeSlide.value - 1;
  if (newVal < 0) {
    activeSlide.value = Number(props?.values?.slides.length - 1)
    return;
  }
  activeSlide.value = newVal;
};
const nextSlide = () => {
  const newVal = activeSlide.value + 1;
  if (newVal > (props?.values?.slides || []).length - 1) {
    activeSlide.value = 0;
    return;
  }
  activeSlide.value = newVal;
};

const isArticlePage = computed(() => {
  return Boolean($route.name === 'articles-slug');
})
const initParams = () => {
  if ($route.name !== 'articles-slug' || !$viewport.isGreaterOrEquals('lg')) {
    return
  }

  const bodyBoundingClient = document.body.getBoundingClientRect();
  const rootBoundingClient = refAccordionGalleryBlock.value.getBoundingClientRect();

  refAccordionGalleryBlock.value.style.marginLeft = `${rootBoundingClient.left * -1}px`;
  refAccordionGalleryBlock.value.style.paddingLeft = `${rootBoundingClient.left}px`;
  refAccordionGalleryBlock.value.style.marginRight = `${(bodyBoundingClient.right - rootBoundingClient.right) * -1}px`;
};

const descriptionFromValues = computed(() => {
  if ($viewport.isLessThan('lg')) {
    return props.values.description_mobile;
  }
  return props.values.description_desktop;
})

onMounted(() => {
  initParams();
});
</script>

<style lang="scss">
:root {
	--agb-width-card: 196px;
	--agb-width-card-open: 847px;
	--agb-spacing-card: 32px;
}

.accordion-gallery-block {
	padding: 62px 0;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
}

.accordion-gallery-block__title {
	margin-bottom: 32px;
}

.accordion-gallery-block__description {
  font-weight: 500;
  line-height: 130%;
  padding-bottom: 32px;
}

.accordion-gallery-block__swiper {
	display: flex;
	transform: translateX(calc((var(--agb-width-card) * var(--active-index) + calc(var(--agb-spacing-card) * var(--active-index))) * -1));
	transition: all 0.3s;

	& > * {
		width: var(--agb-width-card);
		min-width: var(--agb-width-card);
		margin-left: var(--agb-spacing-card);
		transition: all 0.3s;

		&.--active {
			width: var(--agb-width-card-open);
			min-width: var(--agb-width-card-open);
		}

		&:first-child {
			margin-left: 0;
		}
	}
}
.accordion-gallery-block__scroll-bar {
  width: var(--agb-width-card-open);
  height: 1px;
  background-color: rgba(0,0,0,0.1);
  border-radius: 100px;
  margin-top: 24px;
}

@media (max-width: 1699px) {
	:root {
		--agb-spacing-card: 20px;
	}
}
</style>
